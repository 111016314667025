import React, { useState } from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "@/components/layout"

import BackgroundImage from "gatsby-background-image"
import Services from "@/components/services"
import SEO from "@/components/seo"

const axios = require("axios").default

const instance = axios.create({
  baseURL:
    "https://0yym76ym2g.execute-api.eu-west-3.amazonaws.com/ym1/crud/eu-central-1/",
  timeout: 6000,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": "yoWdyAcVah26yYguY2dkzNzVv7KzrFV4mQAfSyCi",
  },
})

const SecondPage = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      service: file(relativePath: { eq: "background-tm-malek.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      phone: file(relativePath: { eq: "phone.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mail: file(relativePath: { eq: "mail.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      address: file(relativePath: { eq: "address.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [subject, setSubject] = useState()
  const [message, setMessage] = useState()
  const [name, setName] = useState()
  const [phone, setPhone] = useState()
  const [email, setEmail] = useState()

  return (
    <Layout>
      <SEO title="Page two" />
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: 250,
          backgroundColor: "#404543",
          filter: "brightness(0.5)",
        }}
      >
        <BackgroundImage
          style={{ height: "100%", width: "100%" }}
          Tag="section"
          fluid={data.service.childImageSharp.fluid}
        ></BackgroundImage>
      </div>

      <div className="col-lg-12">
        <div className="container mt-5 pt-5">
          <div className="row">
            <div className="col-lg-12">
              <h1 style={{ color: "#33af5b", fontSize: 32 }}>
                Contactez l'entreprise Tahtane Malek
              </h1>
              <h2 className="mb-5" style={{ color: "#8bc63e" }}>
                Aménagement d'espaces verts
              </h2>
            </div>
          </div>
          <div className="row mt-5 pt-5 mb-5">
            <div className="col-lg-6 pl-lg-5 pl-0">
              <div className="d-flex flex-column">
                <input
                  className="mt-2"
                  type="text"
                  name="subject"
                  placeholder="Sujet"
                  onChange={(event) => setSubject(event.target.value)}
                />
                <textarea
                  style={{ height: 85 }}
                  className="mt-2"
                  name="message"
                  onChange={(event) => setMessage(event.target.value)}
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <div className="col-lg-6  pl-lg-2 pl-0 pr-lg-5 pr-3">
              <div className="d-flex flex-column">
                <input
                  className="mt-2"
                  type="text"
                  name="name"
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Nom"
                />
                <input
                  className="mt-2"
                  type="text"
                  name="phone"
                  onChange={(event) => setPhone(event.target.value)}
                  placeholder="Numéro de téléphone"
                />
                <input
                  className="mt-2"
                  type="text"
                  name="email"
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="E-Mail"
                />
              </div>
            </div>
            <div className="col-lg-12 pt-4 d-flex justify-content-center pb-5 mb-5">
              <button
                className="actionButton"
                onClick={() =>
                  sendEmail(
                    subject,
                    name,
                    phone,
                    email,
                    message,
                    setName,
                    setEmail,
                    setPhone,
                    setSubject,
                    setMessage
                  )
                }
              >
                Envoyer
              </button>
            </div>

            <div className="col-lg-4 pl-lg-5 pl-0 mt-2">
              <div className="d-flex flex-row">
                <Img
                  style={{ width: 75, height: 75 }}
                  fluid={data.phone.childImageSharp.fluid}
                />
                <div className="ml-3 d-flex flex-column">
                  <strong
                    style={{
                      color: "#33af5b",
                    }}
                  >
                    Numéro de téléphe
                  </strong>
                  <span>05 61 68 12 03 </span>
                  <span>06 57 62 08 56</span>
                  <span>07 96 49 84 33 </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pl-lg-2 pl-0 mt-2">
              <div className="d-flex flex-row">
                <Img
                  style={{ width: 75, height: 75 }}
                  fluid={data.mail.childImageSharp.fluid}
                />
                <div className="ml-3 d-flex flex-column">
                  <strong
                    style={{
                      color: "#33af5b",
                    }}
                  >
                    Adresse E-Mail
                  </strong>
                  <span>contact@ets-tm.dz</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pl-lg-2 pl-0 mt-2">
              <div className="d-flex flex-row">
                <Img
                  style={{ width: 75, height: 75 }}
                  fluid={data.address.childImageSharp.fluid}
                />
                <div className="ml-3 d-flex flex-column">
                  <strong
                    style={{
                      color: "#33af5b",
                    }}
                  >
                    Adresse
                  </strong>
                  <span>Chebli Blida. (Voir la carte)</span>
                </div>
              </div>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1455.971793657444!2d3.0386737!3d36.578885!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzbCsDM0JzQ0LjAiTiAzwrAwMicyMy4yIkU!5e1!3m2!1sfr!2sdz!4v1594813171312!5m2!1sfr!2sdz"
            width="100%"
            height="450"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </div>
      <div className="col-lg-12 mt-5 p-0 pb-5">
        <Services />
      </div>
    </Layout>
  )
}

const sendEmail = async (
  subject,
  name,
  phone,
  email,
  message,
  setName,
  setEmail,
  setPhone,
  setSubject,
  setMessage
) => {
  const sent = await instance({
    method: "POST",
    url: "https://0yym76ym2g.execute-api.eu-west-3.amazonaws.com/ym1/email/s",
    data: JSON.stringify({
      bcc: [],
      cc: [],
      to: ["mohamed.boudjebbar@ets-tm.dz"],
      html: `Email de ${name} du site web : 
        <br/>Phone : ${phone}
        <br/>Email : ${email}
        <br/>Message : ${message}`,
      subject: subject,
      replyTo: [email],
      source: ["tahtane-malek", "yet.marketing"],
    }),
  })
  setName("")
  setEmail("")
  setPhone("")
  setSubject("")
  setMessage("")
}

export default SecondPage
